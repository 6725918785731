import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import CotroliaSiretInput from "../../../../../components/core/input/CotroliaSiretInput";
import { useAuth } from "../../../../auth/hooks/useAuth";

const FieldSiretNumber: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaSiretInput
        label={t("SiretNumber")}
        value={user?.thirdparty.siret}
        disabled
        onValidate={() => {}}
        onChange={() => {}}
      />
    </InterFormControl>
  );
};

export default FieldSiretNumber;
