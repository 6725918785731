import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem } from "@material-ui/core";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../../components/core/input/CotroliaSelect";

const FilterStatus: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const statusEntries = [
    {
      value: "1",
      label: t("InvoiceFilterUnpayed"),
    },
    {
      value: "2",
      label: t("InvoiceFilterPayed"),
    },
    {
      value: "3",
      label: t("InvoiceFilterAbandoned"),
    },
    {
      value: "1,2",
      label: t("InvoiceFilterUnpayedPayed"),
    },
  ];

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{t("FilterLabelInvoiceStatus")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        onClear={() => props.onChange(undefined)}
      >
        {statusEntries.map((entry) => (
          <MenuItem key={entry.value} value={entry.value}>
            {entry.label}
          </MenuItem>
        ))}
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterStatus;
