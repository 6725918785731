import React from "react";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText } from "@material-ui/core";
import InterFormControl from "../../InterFormControl";
import { CarBrand } from "../../../../interventions/types/InterventionTypes";
import SelectCarBrand from "../../../../interventions/components/SelectCarBrand";

const FieldCarBrand: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <SelectCarBrand
        value={intervention.car.carBrand}
        error={!!props.error}
        onChange={(brand?: CarBrand) => {
          setIntervention({
            ...intervention,
            car: { ...intervention.car, carBrand: brand, carModel: undefined },
          });
          props.onChange && props.onChange();
        }}
        required
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldCarBrand;
