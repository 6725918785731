import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem } from "@material-ui/core";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import { range } from "lodash";

const FilterYear: React.FC<{
  label?: string;
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  const years = [...range(2015, currentYear + 1)];

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{props.label ?? t("FilterYear")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        onClear={() => props.onChange(undefined)}
      >
        {years.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterYear;
