import {
  IconButton,
  InputAdornment,
  makeStyles,
  Select,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";

const useStyle = makeStyles((theme) => ({
  select: {
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    "&::before": {
      borderBottom: "none",
    },
    "&:hover:before": {
      borderBottom: "none",
    },

    // Border bottom of the input
    "&.MuiFilledInput-underline": {
      "&:after": {
        width: `calc(100% - ${(theme.spacing(1) + 2) * 2}px)`, // We substract border-radius of each corner
        left: theme.spacing(1) + 2, // We center the line
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
      "&.Mui-error:after": {
        borderColor: theme.palette.error.main,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.text.primary,
      },
      "&.Mui-disabled:before": {
        borderBottomStyle: "none",
      },
    },

    // Border radius on the shadow on focus
    "& .MuiSelect-select:focus": {
      borderRadius: theme.spacing(1) + 2,
    },
  },
  disabled: {
    "& .MuiSelect-icon": {
      display: "none",
    },
  },
}));

const CotroliaSelect = (props) => {
  const classes = useStyle();

  const { onClear, ...selectProps } = props;

  return (
    <Select
      className={
        `${classes.select} ${props.className}` +
        (props.disabled ? ` ${classes.disabled}` : ``)
      }
      endAdornment={
        onClear &&
        props.value && (
          <InputAdornment
            position="end"
            style={{ position: "absolute", right: 30 }}
          >
            <IconButton onClick={() => onClear()} style={{ padding: 8 }}>
              <Clear style={{ width: 20, height: 20 }} />
            </IconButton>
          </InputAdornment>
        )
      }
      style={
        onClear
          ? {
              paddingRight: 0,
            }
          : undefined
      }
      {...selectProps}
    >
      {props.children}
    </Select>
  );
};

export default CotroliaSelect;
