import React from "react";
import { useTranslation } from "react-i18next";
import AppConstant from "../../../../utils/appConstant";
import { FormControl, MenuItem } from "@material-ui/core";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../../components/core/input/CotroliaSelect";

const FilterStatus: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const statusEntries = [
    {
      value: AppConstant.interStatusRegistered,
      label: t("StatusDraft"),
    },
    {
      value: AppConstant.interStatusTransmited,
      label: t("StatusTransmited"),
    },
    {
      value: AppConstant.interStatusToSend,
      label: t("StatusToSend"),
    },
    {
      value: AppConstant.interStatusSended,
      label: t("StatusSended"),
    },
    {
      value: AppConstant.interStatusReceive,
      label: t("StatusReceive"),
    },
    {
      value: AppConstant.interStatusQuoteReceive,
      label: t("StatusQuoteReceive"),
    },
    {
      value: AppConstant.interStatusQuoteAccepted,
      label: t("StatusQuoteAccepted"),
    },
    {
      value: AppConstant.interStatusPaymentPending,
      label: t("StatusPaymentPending"),
    },
    {
      value: AppConstant.interStatusRepair,
      label: t("StatusRepair"),
    },
    {
      value: AppConstant.interStatusOnTheWay,
      label: t("StatusOnTheWay"),
    },
    {
      value: AppConstant.interStatusAlert,
      label: t("StatusAlert"),
    },
    {
      value: AppConstant.interStatusDeclined,
      label: t("StatusDeclined"),
    },
    {
      value: AppConstant.interStatusFinalized,
      label: t("StatusFinalized"),
    },
  ];

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{t("FilterLabelStatus")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        onClear={() => props.onChange(undefined)}
      >
        {statusEntries.map((entry) => (
          <MenuItem key={entry.value} value={entry.value}>
            {entry.label}
          </MenuItem>
        ))}
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterStatus;
