import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem } from "@material-ui/core";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../../components/core/input/CotroliaSelect";

const FilterGarageStatus: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{t("GaragesFilterStatus")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        onClear={() => props.onChange(undefined)}
      >
        <MenuItem value={"0"}>{t("GaragesFilterStatusActive")}</MenuItem>
        <MenuItem value={"1"}>{t("GaragesFilterStatusBlocked")}</MenuItem>
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterGarageStatus;
