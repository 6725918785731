import React, { useState } from "react";
import {
  Box,
  Button,
  debounce,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@material-ui/icons/FilterList";
import { InvoiceFilter } from "../../types/InvoiceTypes";
import FilterMonth from "../../../../components/filters/FilterMonth";
import FilterYear from "../../../../components/filters/FilterYear";
import FilterRef from "./FilterRef";
import FilterStatus from "./FilterStatus";
import FilterGarageName from "../../../garages/components/filters/FilterGarageName";
import FilterCustomerCode from "../../../companies/components/filters/FilterCustomerCode";
import FilterShopCode from "../../../companies/components/filters/FilterShopCode";
import FilterDistributorCode from "../../../distributors/components/filters/FilterDistributorCode";
import FilterInterRef from "./FilterInterRef";

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: "2em",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: theme.spacing(1),
    margin: `${theme.spacing(2)}px 0`,
  },
  actionBtn: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const InvoicesFilters: React.FC<{
  applyFilters: (filters: InvoiceFilter) => void;
}> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [filters, setFilters] = useState<InvoiceFilter>({});

  const debouncedApply = React.useRef(
    debounce((filters) => props.applyFilters(filters), 200),
  ).current;

  const handleChange = (filters: InvoiceFilter) => {
    setFilters(filters);
    debouncedApply(filters);
  };

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <FilterListIcon style={{ marginRight: 5 }} />
        <Typography variant="h6">{t("Filters")}</Typography>
      </Box>

      <Box className={classes.filterContainer}>
        <FilterRef
          value={filters.ref}
          onChange={(value) => handleChange({ ...filters, ref: value })}
        />
        <FilterMonth
          label={t("FilterInvoicesIssueMonth")}
          value={filters.month}
          onChange={(value) => handleChange({ ...filters, month: value })}
        />
        <FilterYear
          label={t("FilterInvoicesIssueYear")}
          value={filters.year}
          onChange={(value) => handleChange({ ...filters, year: value })}
        />
        <FilterInterRef
          value={filters.interRef}
          onChange={(value) => handleChange({ ...filters, interRef: value })}
        />
        <FilterMonth
          label={t("FilterInvoicesInterMonth")}
          value={filters.interDatecMonth}
          onChange={(value) =>
            handleChange({ ...filters, interDatecMonth: value })
          }
        />
        <FilterYear
          label={t("FilterInvoicesInterYear")}
          value={filters.interDatecYear}
          onChange={(value) =>
            handleChange({ ...filters, interDatecYear: value })
          }
        />
        <FilterStatus
          value={filters.fk_statut}
          onChange={(value) => handleChange({ ...filters, fk_statut: value })}
        />
        <FilterGarageName
          value={filters.nom}
          label={t("InvoicesHeaderGarageName")}
          onChange={(value) => handleChange({ ...filters, nom: value })}
        />
        <FilterCustomerCode
          value={filters.customerCode}
          onChange={(value) =>
            handleChange({ ...filters, customerCode: value })
          }
        />
        <FilterShopCode
          value={filters.shopCode}
          onChange={(value) => handleChange({ ...filters, shopCode: value })}
        />
        <FilterDistributorCode
          value={filters.distributerCode}
          onChange={(value) =>
            handleChange({ ...filters, distributerCode: value })
          }
        />
      </Box>
      <Box className={classes.actionBtn}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleChange({})}
        >
          {t("Reset")}
        </Button>
      </Box>
    </Box>
  );
};

export default InvoicesFilters;
