import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaInputLabel from "../../../../../components/core/input/CotroliaInputLabel";
import { FormHelperText } from "@material-ui/core";
import SelectPartType from "../../../../interventions/components/SelectPartType";

const FieldPartType: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl variant="filled" required error={!!props.error}>
      <CotroliaInputLabel id="type-label" error={!!props.error}>
        {t("PartType")}
      </CotroliaInputLabel>
      <SelectPartType
        value={intervention.part.type}
        onChange={(value?: string) => {
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              type: value,
            },
          });
          props.onChange && props.onChange();
        }}
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldPartType;
