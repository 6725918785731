import {
  CircularProgress,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import CotroliaTextField from "./CotroliaTextField";
import { makeStyles } from "@material-ui/styles";
import useDebouncedEffect from "use-debounced-effect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

const CotroliaAutocomplete = ({
  onChange,
  getOptions,
  getOptionLabel,
  freeSolo = false,
  nested = false,
  labelColorDisabled = "",
  ...props
}) => {
  const { t } = useTranslation();
  const classes = makeStyles((theme) => ({
    listbox: {
      padding: 0,
    },
    optionsContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxHeight: "250px",
      borderRadius: theme.spacing(1) + 2,
      backgroundColor: theme.palette.grey[nested ? 50 * 2 : 50],
      overflowY: "scroll",
    },
    option: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1.5),
      "&:hover": {
        backgroundColor: theme.palette.grey[nested ? 100 * 2 : 100],
        cursor: "pointer",
      },
    },
    optionLabel: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
    },
  }))();

  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSearchText(getOptionLabel(props.value));
  }, [getOptionLabel, props.value]);

  useDebouncedEffect(
    () => {
      if (!props.value || getOptionLabel(props.value) !== searchText) {
        refreshOptions();
      }
    },
    200,
    [searchText],
  );

  const refreshOptions = () => {
    setLoading(true);
    getOptions(searchText)
      .then((res) => {
        setOptions(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelect = (option) => {
    setSearchText(option ? getOptionLabel(option) : "");
    setOptions(null);
    onChange(option);
  };

  return (
    <>
      <Autocomplete
        options={options ?? []}
        filterOptions={(x) => x}
        freeSolo={freeSolo}
        autoSelect={freeSolo}
        value={props.value}
        defaultValue={props.value}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, value) => option.id === value.id}
        onInputChange={(_, value) => setSearchText(value)}
        onChange={(_, value) => handleSelect(value)}
        onClose={(_, reason) => {
          if (
            !freeSolo &&
            reason !== "select-option" &&
            getOptionLabel(props.value) !== searchText
          ) {
            setSearchText("");
          }
        }}
        renderInput={(params) => (
          <>
            <CotroliaTextField
              {...params}
              label={props.label}
              error={props.error}
              required={props.required}
              onFocus={() => refreshOptions()}
              disabled={props.disabled}
              InputProps={{
                ...params.InputProps,
                disabled: props.disabled,
                endAdornment: (
                  <>
                    {loading && (
                      <CircularProgress
                        color="primary"
                        size={20}
                        style={{
                          position: "absolute",
                          right: 70,
                          top: "calc(50% - 10px)",
                        }}
                      />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              nested={nested}
              labelColorDisabled={labelColorDisabled}
            />
          </>
        )}
        renderOption={(option) => (
          <Typography variant="body2" className={classes.optionLabel}>
            {getOptionLabel(option)}
          </Typography>
        )}
        classes={{
          option: classes.option,
          noOptions: classes.option,
          paper: classes.optionsContainer,
          listbox: classes.listbox,
          disabled: classes.disabled,
          ...props.classes,
        }}
        className={props.className}
        disabled={props.disabled}
        noOptionsText={t("AutoCompleteNoOption")}
      />
      {props.helperText && (
        <FormHelperText variant="filled">{props.helperText}</FormHelperText>
      )}
    </>
  );
};

export default CotroliaAutocomplete;
