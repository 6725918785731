import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";
import CotroliaTextField from "../../../../components/core/input/CotroliaTextField";

const FilterCustomerRef: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <CotroliaTextField
      label={t("FilterInterCustomerRef")}
      value={props.value ?? ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value);
      }}
      InputProps={{
        endAdornment: props.value ? (
          <InputAdornment position="end">
            <IconButton
              onClick={() => props.onChange(undefined)}
              style={{ padding: 8, marginRight: -4 }}
            >
              <Clear style={{ width: 20, height: 20 }} />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <Search style={{ opacity: 0.6 }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FilterCustomerRef;
