import React from "react";
import SelectCarBrand from "../SelectCarBrand";
import { CarBrand } from "../../types/InterventionTypes";

const FilterCarBrand: React.FC<{
  value?: CarBrand;
  onChange: (value?: CarBrand) => void;
}> = (props) => {
  return <SelectCarBrand value={props.value} onChange={props.onChange} />;
};

export default FilterCarBrand;
