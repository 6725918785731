import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import LanguageSelector from "../../../components/core/LanguageSelector";
import { LegalNotices } from "../../../components/core/LegalNotices";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const AuthFooter: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openLegalNotices, setOpenLegalNotices] = useState<boolean>(false);
  return (
    <>
      <div className={classes.root}>
        <Typography
          variant="caption"
          style={{ opacity: ".5", marginRight: 20 }}
        >
          Version {import.meta.env.VITE_VERSION}
        </Typography>
        <Typography
          variant="caption"
          style={{
            opacity: ".5",
            marginRight: 20,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => setOpenLegalNotices(true)}
        >
          {t("LegalNoticesLinkText")}
        </Typography>
        <LanguageSelector />
      </div>
      <LegalNotices
        open={openLegalNotices}
        onClose={() => {
          setOpenLegalNotices(false);
        }}
      />
    </>
  );
};

export default AuthFooter;
