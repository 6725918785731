import React from "react";
import { useConfiguration } from "../../../hooks/useConfiguration";
import CotroliaAutocomplete from "../../../components/core/input/CotroliaAutocomplete";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { CarBrand } from "../types/InterventionTypes";

const SelectCarBrand: React.FC<{
  value?: CarBrand;
  error?: boolean;
  required?: boolean;
  onChange: (value?: CarBrand) => void;
}> = (props) => {
  const { interventionConfig } = useConfiguration();
  const { t } = useTranslation();

  return (
    <CotroliaAutocomplete
      label={t("CarBrand")}
      value={props.value ?? { id: "-1", label: "" }}
      error={props.error}
      onChange={(value?: CarBrand) => {
        props.onChange && props.onChange(value);
      }}
      getOptions={(searchValue: string) => {
        const brands = interventionConfig.carBrandModel;
        const options: { id: string; label: string }[] = [];
        Object.keys(brands).forEach((key) => {
          options.push({ id: key, label: brands[key].label });
        });
        const filteredOptions = options.filter((opt) =>
          opt.label.toLowerCase().includes(searchValue.toLowerCase()),
        );
        return new Promise((resolve) =>
          resolve(_.sortBy(filteredOptions, (o) => o.label)),
        );
      }}
      getOptionLabel={(option: { id: string; label: string }) => {
        return option?.label ?? "";
      }}
      required={props.required}
    />
  );
};

export default SelectCarBrand;
