import React from "react";
import { ListSubheader, MenuItem } from "@material-ui/core";
import { useConfiguration } from "../../../hooks/useConfiguration";
import CotroliaSelect from "../../../components/core/input/CotroliaSelect";

const SelectPartType: React.FC<{
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { interventionConfig } = useConfiguration();

  return (
    <CotroliaSelect
      id="type"
      labelId="type-label"
      value={props.value && props.value !== "0" ? props.value : ""}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
        !!e.target.value && props.onChange(e.target.value);
      }}
      onClear={() => props.onChange(undefined)}
    >
      {interventionConfig.partType &&
        Object.keys(interventionConfig.partType)
          .sort((a, b) =>
            interventionConfig.partType[a].label.localeCompare(
              interventionConfig.partType[b].label,
            ),
          )
          .map((key) => [
            <ListSubheader
              key={key}
              disableSticky
              style={{ fontWeight: 700, paddingLeft: 30 }}
            >
              {interventionConfig.partType[key].label}
            </ListSubheader>,
            ...(interventionConfig.partType[key].values &&
              Object.keys(interventionConfig.partType[key].values)
                .sort((subkey1, subkey2) =>
                  interventionConfig.partType[key].values[
                    subkey1
                  ].localeCompare(
                    interventionConfig.partType[key].values[subkey2],
                  ),
                )
                .map((subkey) => (
                  <MenuItem value={subkey} key={subkey}>
                    {interventionConfig.partType[key].values[subkey]}
                  </MenuItem>
                ))),
          ])}
    </CotroliaSelect>
  );
};

export default SelectPartType;
