import {
  Box,
  Button,
  debounce,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  CarBrand,
  CarModel,
  InterventionFilter,
  PartType,
} from "../../types/InterventionTypes";
import FilterStatus from "./FilterStatus";
import FilterCustomerRef from "./FilterCustomerRef";
import FilterMonth from "../../../../components/filters/FilterMonth";
import FilterYear from "../../../../components/filters/FilterYear";
import FilterAuthor from "./FilterAuthor";
import FilterPartType from "./FilterPartType";
import FilterCarBrand from "./FilterCarBrand";
import FilterCarModel from "./FilterCarModel";
import { useConfiguration } from "../../../../hooks/useConfiguration";

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: "2em",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: theme.spacing(1),
    margin: `${theme.spacing(2)}px 0`,
  },
  actionBtn: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

const MyInterventionsFilters: React.FC<{
  applyFilters: (filters: InterventionFilter) => void;
}> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const configuration = useConfiguration();

  const [filters, setFilters] = useState<InterventionFilter>({});
  const [partTypeFilter, setPartTypeFilter] = useState<PartType>();
  const [brandFilter, setBrandFilter] = useState<CarBrand>();
  const [modelFilter, setModelFilter] = useState<CarModel>();

  const debouncedApply = React.useRef(
    debounce((filters) => props.applyFilters(filters), 200),
  ).current;

  const handleChange = (filters: InterventionFilter) => {
    setFilters(filters);
    debouncedApply(filters);
  };

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <FilterListIcon style={{ marginRight: 5 }} />
        <Typography variant="h6">{t("Filters")}</Typography>
      </Box>

      <Box className={classes.filterContainer}>
        <FilterStatus
          value={filters.fk_statut}
          onChange={(value) => handleChange({ ...filters, fk_statut: value })}
        />
        <FilterCustomerRef
          value={filters.ref_customer}
          onChange={(value) =>
            handleChange({ ...filters, ref_customer: value })
          }
        />
        <FilterPartType
          value={partTypeFilter}
          onChange={(value) => {
            handleChange({ ...filters, partType: value?.label });
            setPartTypeFilter(value);
          }}
        />
        {configuration.sector === "1" && (
          <>
            {" "}
            <FilterCarBrand
              value={brandFilter}
              onChange={(value) => {
                handleChange({
                  ...filters,
                  brand: value?.label,
                  model: undefined,
                });
                setBrandFilter(value);
                setModelFilter(undefined);
              }}
            />
            <FilterCarModel
              value={modelFilter}
              brand={brandFilter}
              onChange={(value) => {
                handleChange({ ...filters, model: value?.label });
                setModelFilter(value);
              }}
            />
          </>
        )}

        <FilterMonth
          label={t("FilterMonthCreation")}
          value={filters.month}
          onChange={(value) => handleChange({ ...filters, month: value })}
        />
        <FilterYear
          label={t("FilterYearCreation")}
          value={filters.year}
          onChange={(value) => handleChange({ ...filters, year: value })}
        />
        <FilterAuthor
          value={filters.fk_user_author}
          onChange={(value) =>
            handleChange({ ...filters, fk_user_author: value })
          }
        />
      </Box>
      <Box className={classes.actionBtn}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleChange({});
            setBrandFilter(undefined);
            setModelFilter(undefined);
            setPartTypeFilter(undefined);
          }}
        >
          {t("Reset")}
        </Button>
      </Box>
    </Box>
  );
};

export default MyInterventionsFilters;
