import { useCallback } from "react";
import { useApi } from "../../../hooks/useApi";
import {
  Company,
  CompanyFilter,
  CompanySort,
  CompanyEdit,
  Address,
} from "../types/CompaniesTypes";
import { useConfiguration } from "../../../hooks/useConfiguration";
import _ from "lodash";

export const useCompanies = (): {
  searchCompanies: (
    limit: number,
    page: number,
    search: CompanyFilter,
    sort: CompanySort,
  ) => Promise<{ results: Company[]; total: string }>;
  getCompany: (id: string) => Promise<Company | undefined>;
  createCompany: (company: CompanyEdit) => Promise<void>;
  updateCompany: (company: Company) => Promise<void>;
  lockCompany: (childCompanyId: string) => Promise<void>;
  unlockCompany: (childCompanyId: string) => Promise<void>;
  fixAddressCountry: (address?: Address) => Address | undefined;
} => {
  const configuration = useConfiguration();
  const { call } = useApi();

  const searchCompanies = useCallback(
    (
      limit: number,
      page: number,
      search: CompanyFilter,
      sort: CompanySort,
    ): Promise<{ results: Company[]; total: string }> =>
      call("/supercotrolia/companies/search", {
        data: {
          limit,
          page,
          search: _.omitBy(search, (v) => !v),
          order: sort,
        },
      }),
    [call],
  );

  const createCompany = (company: CompanyEdit): Promise<void> => {
    return call("/supercotrolia/companies", { data: company });
  };

  const lockCompany = (childCompanyId: string): Promise<void> => {
    return call(`/supercotrolia/companies/${childCompanyId}/lock`, {
      method: "POST",
    });
  };

  const unlockCompany = (childCompanyId: string): Promise<void> => {
    return call(`/supercotrolia/companies/${childCompanyId}/unlock`, {
      method: "POST",
    });
  };

  const updateCompany = (company: Company): Promise<void> => {
    return call(`/supercotrolia/companies/${company.id}`, {
      data: company,
      method: "PUT",
    });
  };

  const getCompany = useCallback(
    (id: string): Promise<Company | undefined> => {
      return call("/supercotrolia/companies/" + id);
    },
    [call],
  );

  const fixAddressCountry = (address?: Address): Address | undefined => {
    let fixedAddress: Address | undefined = address;
    if (address) {
      fixedAddress = {
        ...address,
        countryCode: address.countryCode ? address.countryCode : "FR",
        country:
          configuration.availableShippingCountries[
            address.countryCode ? address.countryCode : "FR"
          ].label,
      };
    }
    return fixedAddress;
  };

  return {
    searchCompanies,
    getCompany,
    createCompany,
    updateCompany,
    lockCompany,
    unlockCompany,
    fixAddressCountry,
  };
};
