import React from "react";
import { CarBrand, CarModel } from "../../types/InterventionTypes";
import SelectCarModel from "../SelectCarModel";

const FilterCarModel: React.FC<{
  value?: CarModel;
  brand?: CarBrand;
  onChange: (value?: CarModel) => void;
}> = (props) => {
  return (
    <SelectCarModel
      value={props.value}
      brand={props.brand}
      onChange={props.onChange}
    />
  );
};

export default FilterCarModel;
