import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RegisterFormStep1 from "./RegisterFormStep1";
import RegisterFormStep2 from "./RegisterFormStep2";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { Navigate } from "react-router-dom";
import AuthFormContainer from "./AuthFormContainer";

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  desc: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(2),
  },
}));

const RegisterForm: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const configuration = useConfiguration();

  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [professional, setProfessional] = useState(
    !configuration.canRegisterIndividual,
  );
  const [thirdparty, setThirdparty] = useState("");
  const [siret, setSiret] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const handleNext = () => {
    setStep(2);
  };

  const handlePrev = () => {
    setStep(1);
  };

  const displayStep = () => {
    const values = {
      name,
      firstname,
      email,
      professional,
      thirdparty,
      siret,
      password,
      rePassword,
    };

    const step1Handlers = {
      setName,
      setFirstname,
      setEmail,
      setProfessional,
      setThirdparty,
      setSiret,
    };

    const step2Handlers = {
      setPassword,
      setRePassword,
    };

    return step === 1 ? (
      <RegisterFormStep1
        handleNext={handleNext}
        values={values}
        handlers={step1Handlers}
      />
    ) : (
      <RegisterFormStep2
        handlePrev={handlePrev}
        values={values}
        handlers={step2Handlers}
      />
    );
  };

  return (
    <AuthFormContainer>
      {configuration.canRegister ? (
        <>
          <Typography variant="h4" className={classes.title}>
            {t("CreateAccount")}
          </Typography>
          <Typography variant="subtitle1" className={classes.desc}>
            {t("CreateAccountDesc")}
          </Typography>

          {displayStep()}
        </>
      ) : (
        <Navigate to={"/login"} replace />
      )}
    </AuthFormContainer>
  );
};

export default RegisterForm;
