import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import CircleButton from "../components/core/CircleButton";
import { Button, makeStyles } from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation, useNavigate } from "react-router";
import Content from "../components/core/layout/Content";
import InterForm from "../features/newintervention/components/InterForm";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useAuth } from "../features/auth/hooks/useAuth";
import { useNewInterventionForm } from "../features/newintervention/context/NewInterventionContext";
import { useDevice } from "../hooks/useDevice";
import { AlertDialogModal } from "../components/core/AlertDialogModal";
import { Add, Cancel, Warning } from "@material-ui/icons";
import LoadingButton from "../components/core/input/CotroliaLoadingButton";

const useStyle = makeStyles((theme) => ({
  leftBtn: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  rightBtn: {
    margin: "0px 16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
    "& .MuiCircularProgress-svg": {
      width: "auto !important",
      height: "auto !important"
    }
  },
}));

const NewIntervention: React.FC = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const device = useDevice();
  const classes = useStyle();
  const navigate = useNavigate();
  const [displayDialog, setDisplayDialog] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const idParam = query.get("id");
  const { fetchConnectedUser } = useAuth();
  const {
    intervention,
    resetIntervention,
    saveForm,
    loadFromSavedIntervention,
  } = useNewInterventionForm();
  const [sending, setSending] = useState(false);

  useEffect(() => {
    // We want to create a saved inter
    if (!!idParam && intervention.id !== parseInt(idParam)) {
      // Load inter parameters
      loadFromSavedIntervention(idParam).catch((err) => {
        // If intervention not in draf redirect to dashboard (security check)
        if (err.status === 403) {
          window.location.href = "/";
        }
      });
    }
  }, [idParam, intervention.id, loadFromSavedIntervention]);

  const handleCancelClick = () => {
    setDisplayDialog(true);
  };

  const handleSave = async () => {
    try {
      setSending(true);
      await saveForm(intervention);

      // Fetch me to get updated contact list and intervention numbers
      fetchConnectedUser();

      // Display feedback and redirect
      const myAlert = alert.show("", {
        title: t("SaveInterSuccess"),
        close: () => {
          alert.remove(myAlert);
        },
        type: "success",
      } as unknown as AlertCustomOptions);
      navigate("/");
    } catch (err) {
      console.error(err);
      const myAlert = alert.show(t("CreateInterErrorDesc"), {
        title: t("CreateInterError"),
        close: () => {
          alert.remove(myAlert);
        },
        type: "error",
      } as unknown as AlertCustomOptions);
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Header
        focus={false}
        variant="primary"
        title={t("NewIntervention")}
        titleIcon={<Add />}
        titleDesc=""
      >
        {device.isMobile ? (
          <HeaderActions>
            <CircleButton
              className={classes.leftBtn}
              onClick={handleCancelClick}
            >
              <CloseIcon />
            </CircleButton>
            <CircleButton
              onClick={handleSave}
              className={classes.rightBtn}
              variant="contained"
            >
              <SaveIcon />
            </CircleButton>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button
              className={classes.leftBtn}
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={handleCancelClick}
            >
              {t("AbortNewIntervention")}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              endIcon={<SaveIcon />}
              onClick={handleSave}
              isLoading={sending}
              loadingLabel={t("Save")}
              originalLabel={t("Save")}
              className={classes.rightBtn}
            >
              {t("Save")}
            </LoadingButton>
          </HeaderActions>
        )}
      </Header>
      <Content centered>
        <InterForm />
      </Content>

      {displayDialog && (
        <AlertDialogModal
          open={displayDialog}
          icon={<Warning />}
          mainIcon={<Cancel />}
          title={t("ConfirmCancelNewInterTitle")}
          content={t("ConfirmCancelNewInterDesc")}
          confirmText={t("ConfirmCancelNewInter")}
          cancelText={t("NotConfirmCancelNewInter")}
          onClose={() => setDisplayDialog(false)}
          onConfirm={() => {
            setDisplayDialog(false);
            resetIntervention();
            navigate("/myinterventions");
          }}
        />
      )}
    </>
  );
};

export default NewIntervention;
