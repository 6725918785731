import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl } from "@material-ui/core";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";
import SelectPartType from "../SelectPartType";
import { useConfiguration } from "../../../../hooks/useConfiguration";
import { PartType } from "../../types/InterventionTypes";

const FilterPartType: React.FC<{
  value?: PartType;
  onChange: (value?: PartType) => void;
}> = (props) => {
  const { t } = useTranslation();
  const { interventionConfig } = useConfiguration();

  const findPartType = (id?: string) => {
    const allPartTypes = Object.values(interventionConfig.partType)
      .flatMap((category) => Object.entries(category.values))
      .map((entry) => {
        return { id: entry[0], label: entry[1] } as PartType;
      });
    return allPartTypes.find((p) => p.id === id);
  };

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{t("FilterInterPartType")}</CotroliaInputLabel>
      <SelectPartType
        value={props.value?.id}
        onChange={(value?: string) => {
          const partType = findPartType(value);
          props.onChange(partType);
        }}
      />
    </FormControl>
  );
};

export default FilterPartType;
