import React, { useEffect, useMemo, useState } from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import HeaderActions from "../components/core/HeaderActions";
import { useTranslation } from "react-i18next";
import {
  AddCircleOutline,
  Apartment,
  FilterList,
  MoreHoriz,
} from "@material-ui/icons";
import { useDevice } from "../hooks/useDevice";
import CircleButton from "../components/core/CircleButton";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  makeStyles,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Datagrid from "../components/core/Datagrid";
import { useDistributors } from "../features/distributors/hooks/useDistributors";
import { Distributor } from "../features/distributors/types/DistributorsTypes";
import NoDistributor from "../features/distributors/components/NoDistributor";
import DistributorsFilters from "../features/distributors/components/DistributorsFilters";
import {
  CompanyFilter,
  CompanySort,
  CompanySortFields,
} from "../features/companies/types/CompaniesTypes";
import NoFilterResult from "../components/core/NoFilterResult";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    justifyContent: "center",
  },
  firstCol: {
    "&:after": {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      content: "''",
      display: "block",
      height: "50%",
    },
  },
}));

const Distributors: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const device = useDevice();
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<CompanyFilter>({});
  const someFilters = Object.values(filters).some((value) => !!value);
  const [sort, setSort] = useState<CompanySort>({ nom: "ASC" });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { searchDistributors } = useDistributors();

  useEffect(() => {
    setLoading(true);
    searchDistributors(rowsPerPage, page + 1, filters, sort)
      .then((res) => {
        setDistributors(res.results.slice(0, rowsPerPage));
        setTotal(parseInt(res.total));
      })
      .finally(() => setLoading(false));
  }, [filters, page, rowsPerPage, searchDistributors, sort]);

  const datagrid = useMemo(() => {
    return (
      <Datagrid
        rows={distributors}
        rowsPerPage={rowsPerPage}
        page={page}
        total={total}
        enablePageSelector
        onChangePage={(p) => setPage(p)}
        columnsDef={[
          {
            header: t("DistributorsHeaderName"),
            format: (value) => value.name,
            className: classes.firstCol,
            sortOnField: CompanySortFields.nom,
          },
          {
            header: t("DistributorsHeaderCodeDistri"),
            format: (value) => value.distributerCode,
            colWidth: "auto",
          },
          {
            header: t("DistributorsHeaderEmail"),
            format: (value) => value.email,
          },
          {
            header: t("DistributorsHeaderZipCode"),
            format: (value) => value.address?.zipCode,
            colWidth: "auto",
          },
          {
            header: "",
            format: (value) => <ColActions value={value} />,
            colWidth: "auto",
          },
        ]}
        sort={sort}
        firstColSticky
        lastColSticky
        onSort={(sort) => setSort(sort)}
        loading={isLoading}
      />
    );
  }, [classes.firstCol, distributors, isLoading, page, sort, t, total]);

  return (
    <>
      <Header
        focus={false}
        title={t("MenuDistributors")}
        titleIcon={<Apartment />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterList />
              </CircleButton>
              <Link to="/distributor/new" style={{ textDecoration: "none" }}>
                <CircleButton color="primary" variant="contained">
                  <AddCircleOutline />
                </CircleButton>
              </Link>
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterList />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("ToggleFiltersLabel")}
              </Button>
              <Link to="/distributor/new" style={{ textDecoration: "none" }}>
                <Button
                  style={{ margin: "0px 16px" }}
                  color="primary"
                  variant="contained"
                  endIcon={<AddCircleOutline />}
                >
                  {t("NewDistributor")}
                </Button>
              </Link>
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        <Collapse in={displayFilter}>
          <DistributorsFilters
            applyFilters={(filters) => {
              setFilters(filters);
              setPage(0);
            }}
          />
        </Collapse>
        {!isLoading && total === 0 ? (
          <>
            {someFilters ? (
              <NoFilterResult />
            ) : (
              <Box className={classes.root}>
                <NoDistributor />
              </Box>
            )}
          </>
        ) : (
          datagrid
        )}
      </Content>
    </>
  );
};

const ColActions: React.FC<{ value: Distributor }> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = makeStyles({
    root: {
      padding: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: 700,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    action: {
      justifyContent: "flex-start",
      color: theme.palette.primary.main,
      textTransform: "initial",
      "& p": {
        fontWeight: 500,
      },
    },
  })();
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | undefined
  >();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.root}>
          <Typography variant="caption" className={classes.title}>
            {t("DistributorsActionsMenuTitle")}
          </Typography>
          <Button
            className={classes.action}
            size="small"
            onClick={() => navigate(`/distributor/${props.value.id}/edit`)}
          >
            <Typography variant="body2">
              {t("DistributorActionEdit")}
            </Typography>
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default Distributors;
