import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem } from "@material-ui/core";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import { range } from "lodash";
import moment from "moment";

const FilterMonth: React.FC<{
  label?: string;
  value?: string | number;
  onChange: (value?: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const months = [...range(1, 13)];

  return (
    <FormControl variant="filled">
      <CotroliaInputLabel>{props.label ?? t("FilterMonth")}</CotroliaInputLabel>
      <CotroliaSelect
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e.target.value);
        }}
        onClear={() => props.onChange(undefined)}
        style={{ textTransform: "capitalize" }}
      >
        {months.map((month) => (
          <MenuItem
            value={month}
            key={month}
            style={{ textTransform: "capitalize" }}
          >
            {moment()
              .month(month - 1)
              .format("MMMM")}
          </MenuItem>
        ))}
      </CotroliaSelect>
    </FormControl>
  );
};

export default FilterMonth;
