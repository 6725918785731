import React, { useState, useEffect } from "react";
import { useDevice } from "../hooks/useDevice";

interface MenuContextType {
  isOpen: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
}

export const MenuContext = React.createContext<MenuContextType>(
  {} as MenuContextType,
);

export const MenuProvider: React.FC = (props) => {
  const device = useDevice();
  const [isOpen, setIsOpen] = useState<boolean>(!device.isMobile);

  useEffect(() => {
    setIsOpen(!device.isMobile);
  }, [device.isMobile]);

  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        toggleMenu,
        closeMenu,
      }}
      {...props}
    />
  );
};
