import React, { useCallback, useMemo } from "react";
import { InterventionStatus } from "../types/InterventionTypes";
import { useTranslation } from "react-i18next";
import {
  Check,
  Clear,
  GetApp,
  HelpOutline,
  Info,
  LocalAtm,
  MoveToInbox,
  OpenInNew,
  Publish,
  Settings,
  Warning,
  HourglassEmpty,
  ReportProblem,
  CallMade,
  Save,
  LocalShipping,
  AttachMoney,
  Build,
  Beenhere,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/core";
import { useConfiguration } from "../../../hooks/useConfiguration";

export interface InterventionStatusDisplay {
  label?: string;
  action?: string;
  desc?: string;
  icon?: React.ReactNode;
  stepperIcon?: React.ReactNode;
  buttonIcon?: React.ReactNode;
  textColor?: string;
  backgroundColor?: string;
}

export const useInterventionStatus = (): {
  getStatusList: () => InterventionStatus[];
  getStatusDisplay: (status: InterventionStatus) => InterventionStatusDisplay;
} => {
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const theme = useTheme();

  const defaultStatusDisplay: InterventionStatusDisplay = {
    label: "",
    action: t("InterCardDraft"),
    icon: <CallMade />,
    stepperIcon: <CallMade />,
  };

  const allStatus: Record<InterventionStatus, InterventionStatusDisplay> =
    useMemo(() => {
      return {};
    }, []);

  allStatus[InterventionStatus.Registered] = {
    ...defaultStatusDisplay,
    label: t("StatusRegistered"),
    action: t("InterCardDraft"),
    desc: t("InterCardDraftDesc"),
    icon: <Save />,
    stepperIcon: <Save />,
    buttonIcon: <OpenInNew />,
    textColor: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  };

  allStatus[InterventionStatus.Transmited] = {
    ...defaultStatusDisplay,
    label: t("StatusTransmited"),
    action: t("InterCardToModerate"),
    desc: t("InterCardToModerateDesc"),
    icon: <HourglassEmpty />,
    stepperIcon: <HourglassEmpty />,
    buttonIcon: <Check />,
    textColor: theme.palette.text.disabled,
    backgroundColor: theme.palette.background.default,
  };

  allStatus[InterventionStatus.ToSend] = {
    ...defaultStatusDisplay,
    label: t("StatusToSend"),
    action: t("StatusToSend"),
    desc: t("InterCardToSendDesc"),
    icon: <LocalShipping />,
    stepperIcon: <MoveToInbox />,
    buttonIcon: <MoveToInbox />,
    textColor: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  };

  allStatus[InterventionStatus.Sended] = {
    ...defaultStatusDisplay,
    label: t("StatusSended"),
    action: t("StatusSended"),
    desc: t("InterCardSendedDesc"),
    icon: <LocalShipping />,
    stepperIcon: <Publish />,
    buttonIcon: <Publish />,
    textColor: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  };

  allStatus[InterventionStatus.Declined] = {
    ...defaultStatusDisplay,
    label: t("StatusDeclined"),
    action: t("InterCardRefused"),
    desc: t("InterCardRefusedDesc"),
    icon: <ReportProblem />,
    stepperIcon: <Clear />,
    buttonIcon: <Info />,
    textColor: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  };

  allStatus[InterventionStatus.Receive] = {
    ...defaultStatusDisplay,
    label: t("StatusReceive"),
    action: t("StatusReceive"),
    desc: t("InterCardReceiveDesc", { name: configuration.name }),
    icon: <CallMade />,
    stepperIcon: <Check />,
    buttonIcon: <Check />,
    textColor: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  };

  allStatus[InterventionStatus.QuoteReceive] = {
    ...defaultStatusDisplay,
    label: t("StatusQuoteReceive"),
    action: t("StatusQuoteReceive"),
    desc: t("InterCardQuoteReceiveDesc"),
    icon: <AttachMoney />,
    stepperIcon: <HelpOutline />,
    buttonIcon: <HelpOutline />,
    textColor: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  };

  allStatus[InterventionStatus.QuoteAccepted] = {
    ...defaultStatusDisplay,
    label: t("StatusQuoteAccepted"),
    action: t("StatusQuoteAccepted"),
    desc: t("InterCardQuoteAcceptedDesc"),
    icon: <AttachMoney />,
    stepperIcon: <Check />,
    buttonIcon: <Check />,
    textColor: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  };

  allStatus[InterventionStatus.PaymentPending] = {
    ...defaultStatusDisplay,
    label: t("StatusPaymentPending"),
    action: t("StatusPaymentPending"),
    desc: t("InterCardPaymentPendingDesc"),
    icon: <AttachMoney />,
    stepperIcon: <LocalAtm />,
    buttonIcon: <LocalAtm />,
    textColor: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  };

  allStatus[InterventionStatus.Repair] = {
    ...defaultStatusDisplay,
    label: t("StatusRepair"),
    action: t("StatusRepair"),
    desc: t("InterCardRepairDesc"),
    icon: <Build />,
    stepperIcon: <Settings />,
    buttonIcon: <Settings />,
    textColor: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  };

  allStatus[InterventionStatus.OnTheWay] = {
    ...defaultStatusDisplay,
    label: t("StatusOnTheWay"),
    action: t("StatusOnTheWay"),
    desc: t("InterCardOnTheWayDesc"),
    icon: <LocalShipping />,
    stepperIcon: <GetApp />,
    buttonIcon: <GetApp />,
    textColor: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  };

  allStatus[InterventionStatus.Finalized] = {
    ...defaultStatusDisplay,
    label: t("StatusFinalized"),
    action: t("StatusFinalized"),
    desc: t("InterCardFinalizedDesc"),
    icon: <Beenhere />,
    stepperIcon: <Check />,
    buttonIcon: <Check />,
    textColor: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  };

  allStatus[InterventionStatus.Alert] = {
    ...defaultStatusDisplay,
    label: t("StatusAlert"),
    action: t("StatusAlert"),
    icon: <Warning />,
    stepperIcon: <Warning />,
  };

  const getStatusList = (): InterventionStatus[] =>
    Object.keys(allStatus) as unknown as InterventionStatus[];

  const getStatusDisplay = useCallback(
    (status: InterventionStatus): InterventionStatusDisplay =>
      allStatus[parseInt(status.toString())],
    [allStatus],
  ); // status coming from API can be strings instead of numbers sometimes

  return { getStatusList, getStatusDisplay };
};
