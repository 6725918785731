import React from "react";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText } from "@material-ui/core";
import InterFormControl from "../../InterFormControl";
import { CarModel } from "../../../../interventions/types/InterventionTypes";
import SelectCarModel from "../../../../interventions/components/SelectCarModel";

const FieldCarModel: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <SelectCarModel
        value={intervention.car.carModel}
        brand={intervention.car.carBrand}
        error={!!props.error}
        onChange={(model?: CarModel) => {
          setIntervention({
            ...intervention,
            car: { ...intervention.car, carModel: model },
          });
          props.onChange && props.onChange();
        }}
        required
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldCarModel;
