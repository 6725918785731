import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import CircleButton from "../components/core/CircleButton";
import { Button, makeStyles } from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import { useDevice } from "../hooks/useDevice";
import { Add, Cancel, Edit, Warning } from "@material-ui/icons";
import Content from "../components/core/layout/Content";
import DistributorForm from "../features/distributors/components/DistributorForm";
import { AlertDialogModal } from "../components/core/AlertDialogModal";
import { useNavigate, useParams } from "react-router-dom";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useDistributors } from "../features/distributors/hooks/useDistributors";
import { DistributorEdit } from "../features/distributors/types/DistributorsTypes";
import i18next from "i18next";
import { useCompanies } from "../features/companies/hooks/useCompanies";

const useStyle = makeStyles((theme) => ({
  leftBtn: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  rightBtn: {
    margin: "0px 16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const DistributorEditPage: React.FC = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const device = useDevice();
  const navigate = useNavigate();
  const classes = useStyle();
  const { id } = useParams();
  const { fixAddressCountry } = useCompanies();
  const { createDistributor, updateDistributor, getDistributor } =
    useDistributors();
  const [distributor, setDistributor] = useState<DistributorEdit | undefined>();
  const [distributorFetched, setDistributorFetched] = useState<boolean>(false);
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);

  useEffect(() => {
    if (!distributorFetched && id) {
      getDistributor(id)
        .then((distributor) =>
          setDistributor({
            ...distributor,
            address: fixAddressCountry(distributor?.address),
            email: distributor?.thirdPartymainContact?.email,
            firstname: distributor?.thirdPartymainContact?.firstname,
            lastname: distributor?.thirdPartymainContact?.lastname,
            siret: distributor?.siret,
            isLocked: distributor?.isLocked === "1",
          }),
        )
        .finally(() => setDistributorFetched(true));
    }
  }, [distributorFetched, id, getDistributor, fixAddressCountry]);

  const handleSend = () => {
    let sendPromise;
    if (id) {
      sendPromise = updateDistributor({
        ...distributor!,
        thirdPartymainContact: {
          ...distributor!.thirdPartymainContact!,
          firstname: distributor?.firstname,
          lastname: distributor?.lastname,
          phone: distributor?.phone,
        },
        siret: distributor?.siret,
        isLocked: distributor?.isLocked ? "1" : "0",
      });
    } else {
      const newDistributor: DistributorEdit = {
        ...distributor,
        language: i18next.language,
      };
      sendPromise = createDistributor(newDistributor);
    }
    return sendPromise
      .then(() => {
        navigate("/distributors");
        const myAlert = alert.show("", {
          title: t("SaveDistributorSuccess"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);
      })
      .catch(() => {
        const alertTitle = t("Error");
        const alertMessage = t("SaveDistributorError");
        const myAlert = alert.show(alertMessage, {
          title: alertTitle,
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  return (
    <>
      <Header
        focus={false}
        variant="primary"
        title={id ? t("EditDistributorTitle") : t("NewDistributorTitle")}
        titleIcon={id ? <Edit /> : <Add />}
        titleDesc=""
      >
        {device.isMobile ? (
          <HeaderActions>
            <CircleButton
              className={classes.leftBtn}
              onClick={() => setCancelDialogOpen(true)}
            >
              <CloseIcon />
            </CircleButton>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button
              className={classes.leftBtn}
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={() => setCancelDialogOpen(true)}
            >
              {t("AbortEditDistributor")}
            </Button>
          </HeaderActions>
        )}
      </Header>
      <Content centered>
        {(!id || distributor) && (
          <DistributorForm
            distributor={distributor ?? { isLocked: false }}
            edit={!!id}
            onChange={(g) => {
              setDistributor(g);
            }}
            onSend={() => handleSend()}
          />
        )}
      </Content>
      {isCancelDialogOpen && (
        <AlertDialogModal
          open={isCancelDialogOpen}
          icon={<Warning />}
          mainIcon={<Cancel />}
          title={t("ExitEditDistributorTitle")}
          content={t("ExitEditDistributorDesc")}
          confirmText={t("ExitEditDistributorConfirm")}
          cancelText={t("ExitEditDistributorCancel")}
          onClose={() => setCancelDialogOpen(false)}
          onConfirm={() => {
            setCancelDialogOpen(false);
            navigate("/distributors");
          }}
        />
      )}
    </>
  );
};

export default DistributorEditPage;
