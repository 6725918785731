import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Slide,
  useTheme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";
import { useTranslation } from "react-i18next";
import { useConfiguration } from "../../hooks/useConfiguration";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<unknown, string> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const LegalNotices: React.FC<{
  open: boolean;
  onClose: () => void;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const configuration = useConfiguration();

  const classes = makeStyles({
    contentText: {
      // color: theme.palette.secondary.contrastText,
      "& a": {
        color: "inherit",
      },
    },
  })();

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      scroll={"paper"}
      TransitionComponent={Transition}
      maxWidth={"md"}
      PaperProps={{
        style: { backgroundColor: theme.palette.background.default },
      }}
    >
      <DialogTitle>{t("LegalNoticesTitle")}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText className={classes.contentText}>
          {configuration.sector === "1" &&
            (i18n.language.toLowerCase().startsWith("fr") ? (
              <TextCotroliaFR />
            ) : (
              <TextCotroliaEN />
            ))}
          {configuration.sector !== "1" && <TextRepturn />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onClose()}
        >
          {t("LegalNoticesClose")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TextCotroliaFR: React.FC = () => {
  return (
    <>
      <h2>1. Propriété</h2>
      <p>
        L’application est développée pour le compte de :{" "}
        <strong>COTROLIA</strong>
        <br />
        Société SARL au capital de 200 000,00 €,
        <br />
        Siège social : 22 rue des entrepreneurs 44220 COUERON
        <br />
        RCS : 502 442 627 R.C.S. Nantes
        <br />
        Numéro de TVA intracommunautaire : FR79502442627
        <br />
        Contact :{" "}
        <a href="mailto:cotrolia@cotrolia.com">
          <strong>cotrolia@cotrolia.com</strong>
        </a>
        <br />
        Téléphone : 02 28 03 94 41
      </p>

      <h2>2. Éditeur de l’application</h2>
      <p>
        L’application web est éditée par : Code 42
        <br />
        Société SARL au capital de 7500 €<br />
        Siège social : 25, mail Haroun TAZIEFF, 44300 NANTES
        <br />
        RCS : 793 600 735 R.C.S. Nantes
        <br />
        Numéro de TVA intracommunautaire : FR73793600735
        <br />
        Contact :{" "}
        <a href="mailto:support@code42.fr">
          <strong>support@code42.fr</strong>
        </a>
        <br />
        Téléphone : +33 2 53 44 96 42
        <br />
      </p>

      <h2>3. Responsable de la publication</h2>
      <p>
        Le responsable de la publication est : Jean-Charles TROCHON,
        Co-fondateur de Cotrolia
      </p>

      <h2>4. Hébergeur de l’application</h2>
      <p>
        L’application est hébergée par : <strong>Code 42</strong>
        <br />
        Siège social : <strong>25, mail Haroun TAZIEFF, 44300 NANTES</strong>
      </p>

      <h2>5. Conditions d’utilisation</h2>
      <p>
        L’utilisation de cette application implique l’acceptation pleine et
        entière des présentes mentions légales ainsi que des conditions
        générales d’utilisation (CGU). L’utilisateur est invité à consulter
        régulièrement ces documents pour prendre connaissance des éventuelles
        modifications.
      </p>

      <h2>6. Propriété intellectuelle</h2>
      <p>
        Tous les contenus présents sur cette application (textes, images,
        graphismes, logo, icônes, etc.) sont la propriété exclusive de COTROLIA
        et/ou de Code 42 ou sont utilisés avec l’autorisation de leurs auteurs.
        Toute reproduction, distribution, modification ou utilisation, même
        partielle, sans autorisation expresse est strictement interdite.
      </p>

      <h2>7. Données personnelles</h2>
      <p>
        Conformément au Règlement Général sur la Protection des Données (RGPD)
        et à la loi Informatique et Libertés, les données personnelles
        collectées via l’application sont traitées par COTROLIA en tant que
        responsable de traitement.
      </p>
      <p>
        L’utilisateur dispose d’un droit d’accès, de rectification, de
        suppression, ainsi que d’un droit à la portabilité de ses données. Ces
        droits peuvent être exercés en contactant COTROLIA à l’adresse suivante
        : cotrolia@cotrolia.com
      </p>
      <p>
        Pour plus d’informations, veuillez consulter notre politique de
        confidentialité :{" "}
        <a href="https://www.cotrolia.com/politique-de-confidentialite/">
          <strong>
            https://www.cotrolia.com/politique-de-confidentialite/
          </strong>
        </a>
      </p>

      <h2>8. Responsabilité</h2>
      <p>
        COTROLIA et Code 42 mettent tout en œuvre pour assurer le bon
        fonctionnement de l’application. Toutefois, ils ne sauraient être tenus
        responsables en cas de dysfonctionnement, interruption ou
        indisponibilité temporaire de l’application.
      </p>
      <p>
        COTROLIA and Code 42 décline toute responsabilité concernant les
        dommages directs ou indirects pouvant survenir de l’utilisation de
        l’application ou des informations qui y sont présentées.
      </p>

      <h2>9. Loi applicable</h2>
      <p>
        Soumis au droit français, l&#x27;application de Cotrolia est encadré par
        la loi n° 2004-2005 du 21 juin 2004 pour la confiance dans l’économie
        numérique, l’article L.335-2 du Code de la Propriété Intellectuelle et
        la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004.
      </p>

      <h2>10. Contact</h2>
      <p>
        Pour toute question ou réclamation relative à l’application, veuillez
        contacter COTROLIA :{" "}
        <a href="mailto:cotrolia@cotrolia.com">
          <strong>cotrolia@cotrolia.com</strong>
        </a>
      </p>
    </>
  );
};

const TextCotroliaEN: React.FC = () => {
  return (
    <>
      <h2>1. Ownership</h2>
      <p>
        The application is developed on behalf of: <strong>COTROLIA</strong>
        <br />
        A limited liability company (SARL) with a capital of €200,000.00,
        <br />
        Registered office: 22 rue des entrepreneurs, 44220 COUERON, France
        <br />
        RCS: 502 442 627 R.C.S. Nantes
        <br />
        EU VAT number: FR79502442627
        <br />
        Contact:{" "}
        <a href="mailto:cotrolia@cotrolia.com">
          <strong>cotrolia@cotrolia.com</strong>
        </a>
        <br />
        Phone: +33 2 28 03 94 41
      </p>

      <h2>2. Application Publisher</h2>
      <p>
        The web application is published by: <strong>Code 42</strong>
        <br />
        A limited liability company (SARL) with a capital of €7,500,
        <br />
        Registered office: 25, Mail Haroun TAZIEFF, 44300 NANTES, France
        <br />
        RCS: 793 600 735 R.C.S. Nantes
        <br />
        EU VAT number: FR73793600735
        <br />
        Contact:{" "}
        <a href="mailto:support@code42.fr">
          <strong>support@code42.fr</strong>
        </a>
        <br />
        Phone: +33 2 53 44 96 42
      </p>

      <h2>3. Publication Manager</h2>
      <p>
        The publication manager is: <strong>Jean-Charles TROCHON</strong>,
        Co-founder of Cotrolia.
      </p>

      <h2>4. Application Host</h2>
      <p>
        The application is hosted by: <strong>Code 42</strong>
        <br />
        Registered office:{" "}
        <strong>25, Mail Haroun TAZIEFF, 44300 NANTES, France</strong>
      </p>

      <h2>5. Terms of Use</h2>
      <p>
        Using this application implies full and unconditional acceptance of
        these legal notices and the general terms of use (GTU).
        <br />
        Users are encouraged to regularly review these documents to stay
        informed of any updates.
      </p>

      <h2>6. Intellectual Property</h2>
      <p>
        All content on this application (texts, images, graphics, logos, icons,
        etc.) is the exclusive property of
        <strong>COTROLIA</strong> and/or <strong>Code 42</strong> or is used
        with the permission of their authors. Any reproduction, distribution,
        modification, or use, even partial, without express authorization is
        strictly prohibited.
      </p>

      <h2>7. Personal Data</h2>
      <p>
        In accordance with the General Data Protection Regulation (GDPR) and the
        French Data Protection Act, personal data collected through the
        application is processed by <strong>COTROLIA</strong> as the data
        controller.
      </p>
      <p>
        Users have the right to access, rectify, delete, and port their data.
        These rights can be exercised by contacting
        <strong>COTROLIA</strong> at the following address:{" "}
        <a href="mailto:cotrolia@cotrolia.com">
          <strong>cotrolia@cotrolia.com</strong>
        </a>
        .
      </p>
      <p>
        For more information, please consult our privacy policy:{" "}
        <a href="https://www.cotrolia.com/politique-de-confidentialite/">
          <strong>
            https://www.cotrolia.com/politique-de-confidentialite/
          </strong>
        </a>
      </p>

      <h2>8. Liability</h2>
      <p>
        <strong>COTROLIA</strong> and <strong>Code 42</strong> make every effort
        to ensure the proper functioning of the application. However, they
        cannot be held responsible for malfunctions, interruptions, or temporary
        unavailability of the application.
      </p>
      <p>
        <strong>COTROLIA</strong> and <strong>Code 42</strong> disclaim any
        liability for direct or indirect damages resulting from the use of the
        application or the information presented therein.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        Subject to French law, the <strong>Cotrolia</strong> application is
        governed by Law No. 2004-2005 of June 21, 2004, for confidence in the
        digital economy, Article L.335-2 of the Intellectual Property Code, and
        the amended Data Protection Act of January 6, 1978.
      </p>

      <h2>10. Contact</h2>
      <p>
        For any questions or complaints regarding the application, please
        contact: <strong>COTROLIA</strong>:{" "}
        <a href="mailto:cotrolia@cotrolia.com">
          <strong>cotrolia@cotrolia.com</strong>
        </a>
      </p>
    </>
  );
};

const TextRepturn: React.FC = () => {
  return (
    <>
      <h2>1. Propriété</h2>
      <p>
        L’application est développée pour le compte de : REPTURN
        <br />
        Société SARL au capital de 7 500€
        <br />
        Siège social : 7 rue du Fonteny 44220 COUERON
        <br />
        RCS : 880 403 217 R.C.S. Nantes
        <br />
        Numéro de TVA intracommunautaire : FR58880403217
        <br />
        Contact : info@repturn.com <br />
        Téléphone : +33 9 72 10 22 50
        <br />
      </p>

      <h2>2. Éditeur de l’application</h2>
      <p>
        L’application web est éditée par : Code 42
        <br />
        Société SARL au capital de 7500 €<br />
        Siège social : 25, mail Haroun TAZIEFF, 44300 NANTES
        <br />
        RCS : 793 600 735 R.C.S. Nantes
        <br />
        Numéro de TVA intracommunautaire : FR73793600735
        <br />
        Contact : <a href="mailto:support@code42.fr">support@code42.fr</a>
        <br />
        Téléphone : +33 2 53 44 96 42
      </p>

      <h2>3. Responsable de la publication</h2>
      <p>
        Le responsable de la publication est : Jean-Charles TROCHON,
        Co-fondateur de Repturn
      </p>

      <h2>4. Hébergeur de l’application</h2>
      <p>
        L’application est hébergée par : <strong>Code 42</strong>
        <br />
        Siège social : <strong>25, mail Haroun TAZIEFF, 44300 NANTES</strong>
      </p>

      <h2>5. Conditions d’utilisation</h2>
      <p>
        L’utilisation de cette application implique l’acceptation pleine et
        entière des présentes mentions légales ainsi que des conditions
        générales d’utilisation (CGU). L’utilisateur est invité à consulter
        régulièrement ces documents pour prendre connaissance des éventuelles
        modifications.
      </p>

      <h2>6. Propriété intellectuelle</h2>
      <p>
        Tous les contenus présents sur cette application (textes, images,
        graphismes, logo, icônes, etc.) sont la propriété exclusive de REPTURN
        et/ou de Code 42 ou sont utilisés avec l’autorisation de leurs auteurs.
        Toute reproduction, distribution, modification ou utilisation, même
        partielle, sans autorisation expresse est strictement interdite.
      </p>

      <h2>7. Données personnelles</h2>
      <p>
        Conformément au Règlement Général sur la Protection des Données (RGPD)
        et à la loi Informatique et Libertés, les données personnelles
        collectées via l’application sont traitées par REPTURN en tant que
        responsable de traitement.
      </p>
      <p>
        L’utilisateur dispose d’un droit d’accès, de rectification, de
        suppression, ainsi que d’un droit à la portabilité de ses données. Ces
        droits peuvent être exercés en contactant REPTURN COTROLIA à l’adresse
        suivante : cotrolia@cotrolia.com
      </p>
      <p>
        Pour plus d’informations, veuillez consulter notre politique de
        confidentialité :{" "}
        <a href="https://www.repturn.com/mentions-legales/">
          https://www.repturn.com/mentions-legales/
        </a>
      </p>

      <h2>8. Responsabilité</h2>
      <p>
        REPTURN et Code 42 mettent tout en œuvre pour assurer le bon
        fonctionnement de l’application. Toutefois, ils ne sauraient être tenus
        responsables en cas de dysfonctionnement, interruption ou
        indisponibilité temporaire de l’application.
      </p>
      <p>
        REPTURN and Code 42 décline toute responsabilité concernant les dommages
        directs ou indirects pouvant survenir de l’utilisation de l’application
        ou des informations qui y sont présentées.
      </p>
      <h2>9. Loi applicable</h2>
      <p>
        Soumis au droit français, l&#x27;application de REPTURN est encadré par
        la loi n° 2004-2005 du 21 juin 2004 pour la confiance dans l’économie
        numérique, l’article L.335-2 du Code de la Propriété Intellectuelle et
        la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004.
      </p>
      <h2>10. Contact</h2>
      <p>
        Pour toute question ou réclamation relative à l’application, veuillez
        contacter REPTURN : <strong>info@repturn.com</strong>
      </p>
    </>
  );
};
